<template lang="pug">
v-container(fluid)
    v-row
        v-img( cover :src='arbitraje_encabezado.imagen'  height='76vh').d-none.d-md-flex
            
            v-row.fill-height.fill-width.justify-center.align-center
                v-col(cols=12 md=3  style='background-color: rgba(0, 0, 0, .3); margin-bottom: 1em').d-none.d-md-flex.justify-center.align-center
                    h1.font-weight-bold.pb-2.text-uppercase.white--text(v-html='Eventos.encabezado.título').justify-center.text-center
        v-img(  :src='arbitraje_encabezado.imagen' height='300px' width='100%').d-md-none
            v-container.d-md-none
                v-row(  justify='center')
                    v-col( align='center' cols=7 style='background-color: rgba(0, 0, 0, .3);margin-top: 5em ').d-md-none
                        h2.font-weight-bold.pb-2.text-uppercase.white--text(v-html='Eventos.encabezado.título').justify-center
        
    v-row.white.justify-center.align-center
        v-col(cols=12 md=7 style="margin-bottom: 1.5em; margin-top: 1.5em")
            h1.pb-2.text-uppercase.azul--text.justify-center.text-center.font-weight-light.d-md-none.d-none.d-md-block {{ entrada.título }} 
            h3.text-uppercase.azul--text.justify-center.text-center.font-weight-light.d-md-none {{ entrada.título }} 

    v-row.justify-center.align-center(cols=12 md=7 style="margin-bottom: 3em;")
        hr.primary.hr
    v-row(style="max-width: 900px").justify-center.align-center.ml-auto.mr-auto
        v-carousel(hide-delimiters='' height='100%' width='600px').mt-3
            v-carousel-item( v-for='imagen, i of entrada.imágenes' :key='i')
                v-img(:src='imagen'  height='100%' witdh='100%' contain).d-md-none.d-none.d-md-block
                v-img(:src='imagen'  height='100%' witdh='100%' contain).d-md-none

    v-row.white.justify-center.align-center
        v-col(cols=12 md=7 style="margin-bottom: 3em; margin-top: 3em")
            h2.pb-2.text-uppercase.azul--text.justify-center.text-center.font-weight-light Información del evento
    v-row.justify-center.align-center(cols=12 md=7 style="margin-bottom: 2em;")
        hr.primary.hr
    
    v-row(style="max-width: 900px; margin-bottom: 6em").justify-center.align-center.ml-auto.mr-auto.d-md-none.d-none.d-md-block
        p(v-html='entrada.contenido').text-justify
    v-row(style="max-width: 900px; margin-bottom: 1em").justify-center.align-center.ml-auto.mr-auto.d-md-none
        v-col(cols=11 style="margin-bottom: 1em; margin-top: 1em")
            
            p.text-caption(v-html='entrada.contenido').text-justify
 
</template>

<script>
import { mapGetters } from 'vuex';


export default {
    methods: {
        descripcionCorta(descripcion){
            return `${descripcion.substring(0, 300)}...`
        }
    },
    computed: {
        Eventos(){
            return this.Contenido.eventos
        },
        ...mapGetters({
            //-registro: 'Blog/registro',
            entrada: 'Blog/entrada',
        })
    },
    created(){
        console.log(this.entrada, 'Entrada')
        if(!this.entrada.id) this.$store.dispatch('Blog/obtener_por_id', this.$route.params.idEvento)
    },
}

</script>
<style lang="sass" scoped>
.hr
    width: 90%
    height: 5px
    margin: 0px!important
    opacity: 1!important
    border: 0px
</style>